
import Vue from "vue";
import moment from "moment";
// import { downloadAttachedFile } from "@/actions/publication";
import { mapGetters } from "vuex";
import {
  downloadAttachedFile,
  downloadImage,
  getPublication,
} from "@/actions/publication";
import Header from "@/components/Header.vue";
import { Publication } from "@/types/publication";
import { Lease } from "@/types/lease";

export default Vue.extend({
  name: "PublicationDetails",
  components: { Header },
  created() {
    this.getPublicationDetail();
  },
  data() {
    const downloadingFiles: string[] = [];
    const publication: Publication = {} as Publication;
    return {
      publication,
      isLoading: true,
      downloadingFiles,
      messageToShow: "",
      elementToShowMessage: "",
    };
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  methods: {
    getPropertyId() {
      const selectedLease = this.state.selectedLease;

      return (
        this.state.leases.find((e: Lease) => e.id === selectedLease)
          ?.propertyId || ""
      );
    },
    getPublicationDetail() {
      const publicationId = this.$route.query.publicationId as string;
      const propertyId = this.getPropertyId();
      if (publicationId) {
        this.isLoading = true;
        getPublication(publicationId)
          .then((publication) => {
            this.publication = publication;
            if (publication.imageId) {
              downloadImage(
                publication.imageId,
                propertyId,
                publication.id,
                true
              ).then((res) => {
                this.publication.imageSrc = res as string;
              });
            }
          })
          .finally(() => (this.isLoading = false));
      }
    },
    getDateToShow() {
      return moment(this.publication.publicationDate).format(
        "DD [de] MMMM [de] YYYY"
      );
    },
    getImageToShow() {
      return (
        this.publication.imageSrc ||
        require("../assets/" + "images/default-image1.jpg")
      );
    },
    downloadFile(id: string, name: string) {
      this.downloadingFiles = this.downloadingFiles.concat([id]);
      downloadAttachedFile(id, this.publication.id, name)
        .then((result) => (this.messageToShow = String(result)))
        .catch(() => (this.messageToShow = "danger"))
        .finally(() => {
          this.elementToShowMessage = id;
          this.downloadingFiles = this.downloadingFiles.filter(
            (e: string) => e !== id
          );
          setTimeout(() => {
            this.messageToShow = "";
            this.elementToShowMessage = "";
          }, 10000);
        });
    },
  },
});
