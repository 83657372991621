/* eslint-disable @typescript-eslint/no-explicit-any */
import api, { getHeaders } from "@/utils/api";
import store from "@/store";
import { Lease, ContactInformation } from "@/types/lease";
import { Parameters } from "@/types/parameter";
import { Charge, Unit } from "@/types/unit";
import { Annex } from "@/types/annex";
import { CustodyDocument } from "@/types/custodyDocument";
import { User } from "@/types/user";
import { Pet } from "@/types/pet";
import { Vehicle } from "@/types/vehicle";

export const getLeases = () =>
  new Promise((resolve, reject) => {
    api
      .get(`/tenant/contratos`, getHeaders())
      .then((res) => {
        const payload: Lease[] = res.data.data.contratos.map((e: any) => {
          let imageId = "";
          let propertyAddress = "";
          let propertyId = "";
          let propertyName = "";
          let showCommonSpace = false;
          const contactInformation: ContactInformation[] = [];

          if (e.propiedad && e.propiedad.length) {
            const {
              direccion_completa,
              id,
              imagenes,
              nombre,
              propieda_configuracion_contactar_propiedadd,
              propiedad_configuracion_espacios_comunes,
            } = e.propiedad[0];

            imageId = imagenes?.find((e: any) => e.is_principal)?.id || "";
            propertyAddress = direccion_completa;
            propertyId = id;
            propertyName = nombre;

            if (
              propieda_configuracion_contactar_propiedadd &&
              propieda_configuracion_contactar_propiedadd.length
            ) {
              propieda_configuracion_contactar_propiedadd.forEach((f: any) => {
                if (f.contactar_propiedadd && f.contactar_propiedadd.length) {
                  contactInformation.push({
                    name: f.nombre,
                    values: f.contactar_propiedadd.map((g: any) => ({
                      value: g.valor,
                      type: g.medio,
                    })),
                  });
                }
              });
            }

            showCommonSpace =
              !!propiedad_configuracion_espacios_comunes?.espacio_comun_reserva_en_pop_home;
          }
          return {
            contactInformation,
            endDate: e.fecha_fin,
            folio: e.folio,
            id: e.id,
            imageId,
            imageSrc: "",
            isAutomaticRenovation: e.is_renovacion_automatica,
            permissions: {
              showCommonSpace,
            },
            propertyAddress,
            propertyId,
            propertyName,
            residentId: e.residente?.[0]?.residente_id,
            units: e.unidades
              .sort(
                (a: any, b: any) => a.unidad_tipo.orden - b.unidad_tipo.orden
              )
              .map((f: any) => ({
                id: f.id,
                name: f.nombre,
                typeName: f.unidad_tipo.nombre || "",
              })),
          };
        });

        resolve("ok");
        store.commit("setLeases", payload);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const downloadImage = (
  imageId: string,
  leaseId: string,
  propertyId: string
) =>
  new Promise((resolve, reject) => {
    api
      .get(
        `/tenant/propiedades/${propertyId}/imagenes/${imageId}/download`,
        getHeaders()
      )
      .then((res: any) => {
        if (res.data.data && res.data.data.file_data) {
          store.commit("setImageSrcInLease", {
            leaseId,
            imagenSrc: res.data.data.file_data,
          });
        }

        resolve("ok");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getParameters = (id: string) =>
  new Promise((resolve, reject) => {
    api
      .get(`/tenant/contratos/${id}/parametros-comerciales`, getHeaders())
      .then((res) => {
        if (res.data.data?.parametros?.length) {
          const {
            informacion_contrato,
            dia_pago,
            dia_generacion,
            reajuste_monto,
            reajuste_periodicidad,
            reajuste_periodicidad_valor,
            reajuste_monto_tipo,
            retraso_interes_monto,
            retraso_interes_monto_tipo,
            retraso_interes_periodicidad,
            retraso_interes_periodicidad_valor,
            retraso_multa_monto,
          } = res.data.data.parametros[0];
          let holderInfo: any = {};
          let folio = "";
          let startDate = "";
          let endDate = "";
          let nextDateOfReadjustment = "";
          const executiveInfo = {
            name: "",
            phone: "",
            email: "",
          };
          const endorsements: any[] = [];

          if (informacion_contrato.length) {
            const {
              fecha_fin,
              fecha_inicio,
              folio: foliFromApi,
              participantes,
              ejecutivo,
              fecha_proximo_reajuste,
            } = informacion_contrato[0];

            folio = foliFromApi;
            endDate = fecha_fin;
            startDate = fecha_inicio;
            executiveInfo.email = ejecutivo.correo;
            executiveInfo.name = ejecutivo.nombre;
            executiveInfo.phone = ejecutivo.telefono || "";
            nextDateOfReadjustment = fecha_proximo_reajuste;

            participantes.forEach((e: any) => {
              if (e.contrato_contacto_tipo.id === "1") {
                holderInfo = {
                  name: e.nombre,
                  phone: e.telefono,
                  email: e.correo,
                  dni: e.identificador,
                };
              } else if (e.contrato_contacto_tipo.id === "2") {
                endorsements.push({
                  name: e.nombre,
                  phone: e.telefono,
                  email: e.correo,
                  dni: e.identificador,
                });
              }
            });
          }

          const parameters: Parameters = {
            folio,
            startDate,
            endDate,
            executiveInfo,
            holderInfo,
            endorsements,
            paymentDay: dia_pago,
            generatedDay: dia_generacion,
            readjustment: {
              value: reajuste_monto
                ? `${reajuste_monto}${
                    reajuste_monto_tipo.id === "2" ? "%" : ""
                  }`
                : "",
              frequency: reajuste_periodicidad_valor
                ? `Cada ${reajuste_periodicidad_valor} ${reajuste_periodicidad.nombre}`
                : "",
              nextDate: nextDateOfReadjustment,
            },
            penaltyValue: retraso_multa_monto || "",
            rate: {
              value:
                retraso_interes_monto && retraso_interes_monto_tipo?.id
                  ? `${retraso_interes_monto}${
                      retraso_interes_monto_tipo?.id === "2" ? "%" : ""
                    }`
                  : "",
              frequency:
                retraso_interes_periodicidad_valor &&
                retraso_interes_periodicidad.nombre
                  ? `${retraso_interes_periodicidad_valor} ${retraso_interes_periodicidad.nombre}`
                  : "",
            },
          };
          store.commit("setParameters", { id, parameters });
        }
        resolve("ok");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getUnits = (id: string) =>
  new Promise((resolve, reject) => {
    api
      .get(`/tenant/contratos/${id}/unidades`, getHeaders())
      .then((res) => {
        const units: Unit[] = [];
        if (res.data.data.unidades) {
          if (res.data.data.unidades.length) {
            res.data.data.unidades
              .sort(
                (a: any, b: any) => a.unidad_tipo.orden - b.unidad_tipo.orden
              )
              .forEach(
                ({ contrato_unidad_detalle, nombre, unidad_tipo }: any) => {
                  const normalCharges: Charge[] = [];
                  const extraCharges: Charge[] = [];
                  let currencySymbol = "";
                  contrato_unidad_detalle.forEach(
                    ({
                      concepto,
                      divisa,
                      periodos,
                      monto,
                      fecha_inicio,
                      nombre,
                    }: any) => {
                      if (concepto.id === "1" || concepto.id === "2") {
                        normalCharges.push({
                          conceptId: Number(concepto.id),
                          name: nombre,
                          value: monto,
                          startDate: fecha_inicio,
                          term: periodos,
                        });
                      } else {
                        extraCharges.push({
                          conceptId: Number(concepto.id),
                          name: nombre,
                          value: monto,
                          startDate: fecha_inicio,
                          term: periodos,
                        });
                      }
                      if (!currencySymbol) {
                        currencySymbol =
                          divisa.codigo === "UF" ? divisa.codigo : "$";
                      }
                    }
                  );
                  units.push({
                    name: `${unidad_tipo?.nombre || ""} ${nombre}`,
                    charges: {
                      normal: normalCharges.sort(
                        (a: Charge, b: Charge) => a.conceptId - b.conceptId
                      ),
                      extra: extraCharges.sort(
                        (a: Charge, b: Charge) => a.conceptId - b.conceptId
                      ),
                    },
                    currencySymbol,
                  });
                }
              );
          }
        }
        store.commit("setUnits", { id, units });
        resolve("ok");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getAnnexes = (id: string) =>
  new Promise((resolve, reject) => {
    api
      .get(`/tenant/contratos/${id}/anexos`, getHeaders())
      .then((res) => {
        const annexes: Annex[] = [];

        if (res.data.data.anexos) {
          res.data.data.anexos.forEach(
            ({
              adjunto,
              contrato_anexo_tipo,
              id,
              estado,
              estado_firma,
              fecha,
              folio,
              resposable,
            }: any) => {
              annexes.push({
                entityId: id,
                folio,
                type:
                  contrato_anexo_tipo && contrato_anexo_tipo.length
                    ? contrato_anexo_tipo[0].nombre
                    : "",
                status: estado?.nombre || "",
                signStatus: estado_firma?.nombre || "",
                createdDate: fecha,
                responsibleName: resposable,
                id: adjunto && adjunto.length ? adjunto[0].id : "",
              });
            }
          );
        }

        store.commit("setAnnexes", { id, annexes });
        resolve("ok");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getDocuments = (id: string) =>
  new Promise((resolve, reject) => {
    api
      .get(`/tenant/contratos/${id}/custodia-documentos`, getHeaders())
      .then((res) => {
        const custodyDocuments: CustodyDocument[] = [];

        if (res.data.data.custodia_documento) {
          res.data.data.custodia_documento.forEach(
            ({ banco, folio, monto, girado_a }: any) => {
              custodyDocuments.push({
                folio,
                value: monto,
                bankName: banco,
                issuedTo: girado_a,
              });
            }
          );
        }

        store.commit("setCustodyDocuments", { id, custodyDocuments });
        resolve("ok");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getResidents = (id: string) =>
  new Promise((resolve, reject) => {
    api
      .get(`/tenant/contratos/${id}/residentes`, getHeaders())
      .then((res) => {
        const residents: User[] = [];

        if (res.data?.data?.residentes) {
          res.data.data.residentes.forEach(
            ({
              fecha_nacimiento,
              identificador,
              email,
              nombre,
              apellido,
              genero,
              id: residentId,
              is_fumador,
              nacionalidad,
              ocupacion,
              telefono,
            }: any) => {
              residents.push({
                birthday: fecha_nacimiento || "",
                dni: identificador,
                email,
                firstName: nombre,
                gender: genero && genero.nombre ? genero.nombre : "",
                id: residentId,
                isSmoker: is_fumador,
                lastName: apellido,
                nationality:
                  nacionalidad && nacionalidad.nacionalidad
                    ? nacionalidad.nacionalidad
                    : "",
                occupation: ocupacion || "",
                phone: telefono || "",
              });
            }
          );
        }

        store.commit("setResidents", { id, residents });
        resolve("ok");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getPets = (id: string) =>
  new Promise((resolve, reject) => {
    api
      .get(`/tenant/contratos/${id}/mascotas`, getHeaders())
      .then((res) => {
        const pets: Pet[] = [];

        if (res.data?.data?.mascotas) {
          res.data.data.mascotas.forEach(
            ({ nombre, animal_tipo, mascota_tamanio, descripcion }: any) => {
              pets.push({
                name: nombre || "",
                type:
                  animal_tipo && animal_tipo.nombre ? animal_tipo.nombre : "",
                size:
                  mascota_tamanio && mascota_tamanio.nombre
                    ? mascota_tamanio.nombre
                    : "",
                description: descripcion,
              });
            }
          );
        }

        store.commit("setPets", { id, pets });
        resolve("ok");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getVehicles = (id: string) =>
  new Promise((resolve, reject) => {
    api
      .get(`/tenant/contratos/${id}/vehiculos`, getHeaders())
      .then((res) => {
        const vehicles: Vehicle[] = [];

        if (res.data?.data?.vehiculos) {
          res.data.data.vehiculos.forEach(
            ({
              vehiculo_tipo,
              color,
              modelo,
              marca,
              placa,
              observacion,
            }: any) => {
              vehicles.push({
                type:
                  vehiculo_tipo && vehiculo_tipo.nombre
                    ? vehiculo_tipo.nombre
                    : "",
                brand: marca || "",
                color: color || "",
                model: modelo || "",
                plate: placa || "",
                observation: observacion || "",
              });
            }
          );
        }

        store.commit("setVehicles", { id, vehicles });
        resolve("ok");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const selectLease = (id: string) => {
  localStorage.setItem("lease_id", id);
  store.commit("setSelectedLease", id);
};
