import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import CompleteRegisterView from "@/views/CompleteRegisterView.vue";
import LoginView from "@/views/LoginView.vue";
import BillView from "@/components/Bill.vue";
import SelectLease from "@/components/SelectLease.vue";
import LeaseView from "@/components/LeaseView.vue";
import RecoverPassword from "@/views/RecoverPassword.vue";
import Community from "@/components/Community.vue";
import ReserveCommonSpace from "@/components/ReserveCommonSpace.vue";
import MyReserve from "@/components/MyReserve.vue";
import PublicationDetails from "@/components/PublicationDetails.vue";
import Profile from "@/components/Profile.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "select-lease",
        name: "select-lease",
        component: SelectLease,
      },
      {
        path: "/",
        name: "bill",
        component: BillView,
      },
      {
        path: "lease",
        name: "lease",
        component: LeaseView,
      },
      {
        path: "community",
        name: "community",
        component: Community,
      },
      {
        path: "reserve-common-space",
        name: "reserve-common-space",
        component: ReserveCommonSpace,
      },
      {
        path: "my-reserve",
        name: "my-reserve",
        component: MyReserve,
      },
      {
        path: "publication",
        name: "publication",
        component: PublicationDetails,
      },
      {
        path: "profile",
        name: "profile",
        component: Profile,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/complete-register/:token/:email",
    name: "complete-register",
    component: CompleteRegisterView,
  },
  {
    path: "/recover-password",
    name: "recover-password",
    component: RecoverPassword,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const validRoutesWithoutToken: boolean =
    to.name === "login" ||
    to.name === "complete-register" ||
    to.name === "recover-password";

  if (validRoutesWithoutToken) {
    next();
  } else if (!localStorage.getItem("pop_home_token")) {
    next({ name: "login" });
  } else if (to.name !== "select-lease" && !localStorage.getItem("lease_id")) {
    next({ name: "select-lease" });
  } else if (
    (to.name === "select-lease" || to.name === "home") &&
    localStorage.getItem("lease_id")
  ) {
    next({ name: "bill" });
  } else {
    next();
  }
});

export default router;
