
import Vue from "vue";
import { recoverPassword } from "@/actions/auth";

export default Vue.extend({
  name: "RecoverPasswordView",
  data() {
    return {
      form: {
        email: "",
      },
      loading: false,
      submitted: false,
      showNotificationMessage: false,
    };
  },
  methods: {
    emailValidation() {
      return this.submitted && this.getInvalidFeedbackEmail() ? false : null;
    },
    getInvalidFeedbackEmail() {
      const { email } = this.form;

      if (!email) {
        return "Requerido";
      } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
        return "El correo ingresado no está bien escrito";
      }

      return "";
    },
    onSubmit(event: { preventDefault: () => void }) {
      event.preventDefault();
      this.submitted = true;
      this.showNotificationMessage = false;
      const { email } = this.form;

      if (this.emailValidation() === null) {
        this.loading = true;
        recoverPassword(email)
          .then(() => {
            this.showNotificationMessage = true;
          })
          .finally(() => (this.loading = false));
      }
    },
    disableSummit() {
      if (this.loading) {
        return true;
      }
      return this.submitted && this.showNotificationMessage;
    },
  },
});
