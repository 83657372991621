/* eslint-disable @typescript-eslint/no-explicit-any */
import api, { getHeaders } from "@/utils/api";
import { downloadFile, generateFilter } from "@/utils";
import store from "@/store";
import { Document } from "@/types/document";
import { Publication } from "@/types/publication";

const getFormattedPublication = (data: any[]) => {
  const publications: Publication[] = [];
  data.forEach(
    ({
      mensaje,
      titulo,
      id,
      imagen,
      fecha_envio,
      documentos,
      ejecutivo,
    }: any) => {
      const imageId = imagen && imagen.length ? imagen[0].id : "";
      const attachedFiles: Document[] = [];

      if (documentos && documentos.length) {
        documentos.forEach((e: any) => {
          const splitted = e.nombre_archivo.split(".");

          attachedFiles.push({
            entityId: "",
            id: e.id,
            name: e.descripcion,
            type: splitted[splitted.length - 1],
            weight: e.peso,
          });
        });
      }

      publications.push({
        publicationDate: fecha_envio,
        imageId,
        imageSrc: "",
        title: titulo,
        body: mensaje,
        attachedFiles,
        id,
        publishedBy: ejecutivo?.nombre || "",
      });
    }
  );

  return publications;
};

export const getPublications = (propertyId: string, isList = false, page = 0) =>
  new Promise((resolve, reject) => {
    let filterToApply = "";

    if (page) {
      filterToApply += "&page=" + page;
    }

    filterToApply += generateFilter("publicacion_filter", {
      propiedadId: [propertyId],
    });

    api
      .get(`/tenant/publicaciones?${filterToApply}`, getHeaders())
      .then((res) => {
        const publications: Publication[] = [];
        res.data.data.publicaciones.forEach(
          ({
            mensaje,
            titulo,
            id,
            imagen,
            fecha_envio,
            documentos,
            ejecutivo,
          }: any) => {
            const imageId = imagen && imagen.length ? imagen[0].id : "";
            const attachedFiles: Document[] = [];

            if (documentos && documentos.length) {
              documentos.forEach((e: any) => {
                const splitted = e.nombre_archivo.split(".");

                attachedFiles.push({
                  entityId: "",
                  id: e.id,
                  name: e.descripcion,
                  type: splitted[splitted.length - 1],
                  weight: e.peso,
                });
              });
            }

            publications.push({
              publicationDate: fecha_envio,
              imageId,
              imageSrc: "",
              title: titulo,
              body: mensaje,
              attachedFiles,
              id,
              publishedBy: ejecutivo?.nombre || "",
            });

            getPublication(id);

            if (imageId && !isList) {
              downloadImage(imageId, propertyId, id, isList);
            }
          }
        );

        if (!isList) {
          store.commit("setPublications", { publications, id: propertyId });
          resolve("ok");
        } else {
          resolve({ publications, pager: res.data.data.pager });
        }
      })
      .catch((err) => {
        if (!isList) {
          store.commit("setPublications", { publications: [], id: propertyId });
        }
        reject(err);
      });
  });

export const getPublication = (id: string): Promise<Publication> =>
  new Promise((resolve, reject) => {
    api
      .get(`/tenant/publicaciones/${id}`, getHeaders())
      .then((res) => {
        resolve(getFormattedPublication(res.data.data.publicacion)[0]);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const downloadImage = (
  imageId: string,
  propertyId: string,
  publicationId: string,
  isList: boolean
) =>
  new Promise((resolve, reject) => {
    api
      .get(
        `/tenant/publicaciones/${publicationId}/imagenes/${imageId}/download`,
        getHeaders()
      )
      .then((res: any) => {
        if (res.data.data && res.data.data.file_data) {
          if (isList) {
            resolve(res.data.data.file_data);
          } else {
            store.commit("setImageSrcPublication", {
              propertyId,
              publicationId,
              imagenSrc: res.data.data.file_data,
            });
          }
        }

        resolve("ok");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const downloadAttachedFile = (
  attachId: string,
  publicationId: string,
  name: string
) =>
  new Promise((resolve, reject) => {
    api
      .get(
        `/tenant/publicaciones/${publicationId}/adjuntos/${attachId}/download`,
        getHeaders()
      )
      .then((res: any) => {
        if (res.data.data && res.data.data.file_data) {
          const { file_data } = res.data.data;
          downloadFile(file_data, name);
          resolve("success");
        }
        resolve("danger");
      })
      .catch((err) => {
        reject(err);
      });
  });
