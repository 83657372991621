
import Vue from "vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import PWAModal from "@/components/PWAModal.vue";
import { login } from "@/actions/auth";

export default Vue.extend({
  name: "LoginView",
  components: { NotificationMessage, PWAModal },
  data() {
    return {
      form: {
        user: "",
        pass: "",
      },
      loading: false,
      remenber: false,
      showNotificationMessage: false,
      submitted: false,
    };
  },
  methods: {
    userValidation() {
      return this.submitted && !this.form.user ? false : null;
    },
    passValidation() {
      return this.submitted && !this.form.pass ? false : null;
    },
    onSubmit(event: { preventDefault: () => void }) {
      event.preventDefault();
      this.submitted = true;
      this.showNotificationMessage = false;
      const { user, pass } = this.form;

      if (user && pass) {
        this.loading = true;
        login(pass, user)
          .then((res) => {
            if (res === "ok") {
              this.$router.push("/select-lease");
            }
          })
          .catch(() => (this.showNotificationMessage = true))
          .finally(() => (this.loading = false));
      }
    },
  },
});
