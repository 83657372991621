
import Vue from "vue";

export default Vue.extend({
  name: "NotificationMessage",
  props: {
    color: String,
    fontSize: String,
    message: String,
    showIcon: Boolean,
  },
  methods: {
    style() {
      return `font-size: ${this.fontSize}`;
    },
  },
});
