
import Vue from "vue";
import {
  downloadAttachedFile,
  getCommonSpaces,
  getReserves,
} from "@/actions/commonSpaces";
import { mapGetters } from "vuex";
import { Lease } from "@/types/lease";
import { getAvailableDays, getFormattedValue } from "../utils";
import { CommonSpace, Reserve } from "@/types/commonSpace";
import moment from "moment";
import { Fragment } from "vue-fragment";
import NotificationMessage from "@/components/NotificationMessage.vue";
import update from "immutability-helper";

export default Vue.extend({
  name: "CommonSpacesView",
  components: { Fragment, NotificationMessage },
  created() {
    const selectedTabInCommonSpaces = this.$route.query
      .selectedTabInCommonSpaces as string;
    if (selectedTabInCommonSpaces === "myReserves") {
      this.onSelectTab(1);
      this.$router.replace({ query: {} });
    } else {
      this.onSelectTab(0);
    }
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  methods: {
    formattedValue(amount: string) {
      return getFormattedValue(amount);
    },
    onSelectTab(index: number) {
      if (index === 0 && this.commonSpaces.length === 0) {
        this.getCommonSpacesList();
      }

      if (
        index === 1 &&
        this.myPastReserves.length === 0 &&
        this.myFutureReserves.length === 0
      ) {
        this.getMyReservesList();
      }
      this.selectedTab = index;
    },
    getCommonSpacesList() {
      const propertyId = this.getPropertyId();
      if (propertyId) {
        this.isLoadingCommonSpaces = true;
        getCommonSpaces(propertyId)
          .then(({ commonSpaces }) => {
            this.commonSpaces = commonSpaces;
            this.commonSpacesFiltered = commonSpaces;
            this.commonSpaces.forEach((e) => {
              if (e.images.length) {
                downloadAttachedFile(e.images[0].id, e.id).then((res) => {
                  this.commonSpaceToImage = update(this.commonSpaceToImage, {
                    [e.id]: { $set: res as string },
                  });
                });
              }
            });
          })
          .finally(() => (this.isLoadingCommonSpaces = false));
      }
    },
    getMyReservesList() {
      const selectedLease = this.state.selectedLease;
      if (selectedLease) {
        this.isLoadingMyReserves = true;
        this.myPastReserves = [];
        this.myFutureReserves = [];
        getReserves({ leaseId: selectedLease })
          .then(({ reserves }) => {
            reserves
              .sort((a: Reserve, b: Reserve) =>
                a.reservationDate > b.reservationDate ? 1 : -1
              )
              .forEach((e: Reserve) => {
                if (e.reservationDate <= moment().format("YYYY-MM-DD")) {
                  this.myPastReserves.push(e);
                } else {
                  this.myFutureReserves.push(e);
                }
              });
          })
          .finally(() => (this.isLoadingMyReserves = false));
      }
    },
    getPropertyId() {
      const selectedLease = this.state.selectedLease;

      return (
        this.state.leases.find((e: Lease) => e.id === selectedLease)
          ?.propertyId || ""
      );
    },
    getImageToShow(imageSrc: string) {
      return imageSrc || require("../assets/" + "images/default-image1.jpg");
    },
    getAvailableDaysToShow(availableDays: number[]) {
      return getAvailableDays(availableDays);
    },
    getDateToShow(date: string): string {
      return moment(date).format("dddd DD-MM-YYYY");
    },
    handleInput() {
      clearTimeout(this.delayTimer);

      this.delayTimer = setTimeout(() => {
        this.commonSpacesFiltered = this.commonSpaces.filter((e) =>
          e.name
            .toLowerCase()
            .includes(this.searchTextInCommonSpaces.toLowerCase())
        );
      }, 500);
    },
  },
  data() {
    const commonSpaces: CommonSpace[] = [];
    const commonSpacesFiltered: CommonSpace[] = [];
    const myPastReserves: Reserve[] = [];
    const myFutureReserves: Reserve[] = [];
    const commonSpaceToImage: { [clave: string]: string } = {};

    return {
      commonSpaceToImage,
      commonSpaces,
      commonSpacesFiltered,
      searchTextInCommonSpaces: "",
      delayTimer: 0,
      isLoadingCommonSpaces: true,
      isLoadingMyReserves: true,
      myFutureReserves,
      myPastReserves,
      selectedTab: 0,
      tabs: ["Nueva reserva", "Mis reservas"],
    };
  },

  watch: {
    "state.selectedLease"() {
      this.getCommonSpacesList();
      this.getMyReservesList();
    },
    "state.leases"() {
      this.getCommonSpacesList();
    },
  },
});
