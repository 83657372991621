/* eslint-disable @typescript-eslint/no-explicit-any */
import api, { getHeaders } from "@/utils/api";
import { generateFilter } from "@/utils";
import { CommonSpace, NewReserve, Reserve } from "@/types/commonSpace";

export const getCommonSpaces = (
  propertyId: string,
  commonSpaceId?: string
): Promise<{ commonSpaces: CommonSpace[] }> =>
  new Promise((resolve, reject) => {
    const filterToApply = generateFilter("espacio_comun_filter", {
      propiedadId: propertyId,
      ...(commonSpaceId ? { id: commonSpaceId } : {}),
    });

    api
      .get(`/tenant/espacio-comun?${filterToApply}`, getHeaders())
      .then((resp) => {
        const commonSpaces: CommonSpace[] =
          resp.data?.data?.espaciosComunes?.map((e: any) => ({
            availableDays: e.dias_reserva.map((f: any) =>
              f.id === 7 ? 0 : f.id
            ),
            id: e.id,
            images: e.adjuntos?.map((f: any) => ({ id: f.id, src: "" })) || [],
            name: e.nombre,
            reserveValue: e.monto_reserva,
            cleaningValue: e.monto_aseo,
            warrantyValue: e.monto_garantia,
            currencyCode: e.divisa.codigo,
            availableFromTheTime: e.hora_desde,
            availableToTheTime: e.hora_hasta,
            maxTimeInMinutes: Number(e.minutos_maximo_reserva || 0),
          })) || [];
        resolve({ commonSpaces });
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getReserves = ({
  leaseId,
  id,
}: {
  leaseId?: string;
  id?: string;
}): Promise<{ reserves: Reserve[] }> =>
  new Promise((resolve, reject) => {
    const filterToApply = generateFilter("espacio_comun_reserva_filter", {
      ...(id ? { id } : {}),
      ...(leaseId ? { contratoId: leaseId } : {}),
    });

    api
      .get(`/tenant/espacio-comun-reserva?${filterToApply}`, getHeaders())
      .then((resp) => {
        const reserves: Reserve[] =
          resp.data.data?.espacioComunReservas?.map((e: any) => {
            const [reservationDate, startTime] =
              e.fecha_inicio_reserva.split(" ");
            const [, endTime] = e.fecha_termino_reserva.split(" ");

            return {
              cleaningValue: e.monto_aseo?.split(".")[0],
              commonSpaceName: e.espacio_comun?.nombre || "",
              currencyCode: e.divisa?.codigo || "",
              endTime: endTime.slice(0, 5),
              id: e.id,
              reservationDate,
              reserveValue: e.monto_reserva?.split(".")[0],
              startTime: startTime.slice(0, 5),
              warrantyValue: e.monto_garantia?.split(".")[0],
            };
          }) || [];
        resolve({ reserves });
      })
      .catch((err) => {
        reject(err);
      });
  });

export const downloadAttachedFile = (fileId: string, commonSpaceId: string) =>
  new Promise((resolve, reject) => {
    api
      .get(
        `/tenant/espacio-comun/${commonSpaceId}/adjuntos/${fileId}/download`,
        getHeaders()
      )
      .then((res: any) => {
        if (res.data.data && res.data.data.file_data) {
          resolve(res.data.data.file_data);
        }

        resolve("ok");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const reserveCommonSpace = (
  reserve: NewReserve
): Promise<{ reserveId: string; error: boolean }> =>
  new Promise((resolve, reject) => {
    api
      .post(`/tenant/espacio-comun-reserva`, reserve, getHeaders())
      .then((res) => {
        resolve({
          reserveId: res.data.data.espacioComunReserva[0].id,
          error: false,
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          resolve({
            reserveId: recorrerErrores(error.response.data.data),
            error: true,
          });
        } else {
          reject(error);
        }
      });
  });

export function recorrerErrores(valores: any): string {
  let result = "";
  valores.forEach((item: any) => {
    result += "[* " + item.detail + "\n]";
  });
  return result;
}
