
import { Bill } from "@/types/bill";
import { getFormattedValue } from "@/utils";
import moment from "moment";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "BillsPending",
  methods: {
    getBillsPending() {
      this.billsPending = this.state.bills[this.state.selectedLease].filter(
        (e: Bill) => e.stateId === 100 || e.stateId === 110
      );
    },
    getMonth(dateCreated: string, dateExpired: string): string {
      return moment(dateCreated || dateExpired).format("MMMM YYYY");
    },
    getDateToShow(dateExpired: string): string {
      return moment(dateExpired).format("DD-MM-YYYY");
    },
    formattedValue(amount: string) {
      return getFormattedValue(amount);
    },
    getBillColor(stateId: number) {
      return stateId === 100 ? "yellow" : "red";
    },
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  watch: {
    "state.selectedLease"() {
      this.getBillsPending();
    },
  },
  props: {
    setBillToShow: Function,
  },
  mounted() {
    this.getBillsPending();
  },
  data() {
    const billsPending: Bill[] = [];
    return {
      billsPending,
    };
  },
});
