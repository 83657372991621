
import Vue from "vue";
import { PropType } from "vue/types/v3-component-props";

export default Vue.extend({
  name: "HeaderView",
  props: {
    title: String as PropType<string>,
    goBack: Function,
    withoutPadding: Boolean,
  },
});
