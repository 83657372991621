/* eslint-disable @typescript-eslint/no-explicit-any */
import api, { getHeaders } from "@/utils/api";
import { downloadFile } from "@/utils";
import { Document } from "@/types/document";
import store from "@/store";

const getDocuments = (resp: any): Document[] => {
  const documents: Document[] = [];
  if (resp) {
    resp.forEach((e: any) => {
      const splitted = e.archivo.nombre_archivo.split(".");
      documents.push({
        documentType: e.archivo.tipodocumento,
        entityId: e.id,
        id: e.archivo.id,
        name: e.archivo.descripcion || e.archivo.nombre_archivo,
        type: splitted[splitted.length - 1],
        weight: e.archivo.peso,
      });
    });
  }

  return documents;
};

export const getRates = (leaseId: string) =>
  new Promise((resolve, reject) => {
    api
      .get(`/tenant/contratos/${leaseId}/documentos/cotizacion`, getHeaders())
      .then((res: any) => {
        resolve("ok");
        store.commit("setDocuments", {
          documents: getDocuments(res.data.data.cotizaciones),
          key: "rates",
          leaseId,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getEvaluations = (leaseId: string) =>
  new Promise((resolve, reject) => {
    api
      .get(
        `/tenant/contratos/${leaseId}/documentos/contacto-evaluacion`,
        getHeaders()
      )
      .then((res: any) => {
        resolve("ok");
        store.commit("setDocuments", {
          documents: getDocuments(res.data.data.contacto_evaluaciones),
          key: "evaluations",
          leaseId,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getLeases = (leaseId: string) =>
  new Promise((resolve, reject) => {
    api
      .get(`/tenant/contratos/${leaseId}/documentos/contrato`, getHeaders())
      .then((res: any) => {
        resolve("ok");
        store.commit("setDocuments", {
          documents: getDocuments(res.data.data.contratos),
          key: "leases",
          leaseId,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getAnnexes = (leaseId: string) =>
  new Promise((resolve, reject) => {
    api
      .get(
        `/tenant/contratos/${leaseId}/documentos/contrato-anexo`,
        getHeaders()
      )
      .then((res: any) => {
        resolve("ok");
        store.commit("setDocuments", {
          documents: getDocuments(res.data.data.contrato_anexos),
          key: "annexes",
          leaseId,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getImages = (leaseId: string) =>
  new Promise((resolve, reject) => {
    api
      .get(`/tenant/contratos/${leaseId}/documentos/unidad`, getHeaders())
      .then((res: any) => {
        resolve("ok");
        store.commit("setDocuments", {
          documents: getDocuments(res.data.data.unidades),
          key: "images",
          leaseId,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getCheckin = (leaseId: string) =>
  new Promise((resolve, reject) => {
    api
      .get(
        `/tenant/contratos/${leaseId}/documentos/check-unidad/100`,
        getHeaders()
      )
      .then((res: any) => {
        resolve("ok");
        store.commit("setDocuments", {
          documents: getDocuments(res.data.data.check_unidades),
          key: "checkIn",
          leaseId,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getCheckout = (leaseId: string) =>
  new Promise((resolve, reject) => {
    api
      .get(
        `/tenant/contratos/${leaseId}/documentos/check-unidad/200`,
        getHeaders()
      )
      .then((res: any) => {
        resolve("ok");
        store.commit("setDocuments", {
          documents: getDocuments(res.data.data.check_unidades),
          key: "checkOut",
          leaseId,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });

export const downloadRate = (id: string, name: string) =>
  new Promise((resolve, reject) => {
    api
      .get(`/tenant/cotizacion/${id}/pdf`, getHeaders())
      .then((res: any) => {
        if (res.data.data && res.data.data.file_data) {
          const { file_data } = res.data.data;
          downloadFile(file_data, name);
          resolve("success");
        }
        resolve("danger");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const downloadEvaluation = (
  id: string,
  entityId: string,
  name: string
) =>
  new Promise((resolve, reject) => {
    api
      .get(
        `/tenant/contacto-evaluacion/${entityId}/adjuntos/${id}/download`,
        getHeaders()
      )
      .then((res: any) => {
        if (res.data.data && res.data.data.file_data) {
          const { file_data } = res.data.data;
          downloadFile(file_data, name);
          resolve("success");
        }

        resolve("danger");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const downloadLease = (id: string, entityId: string, name: string) =>
  new Promise((resolve, reject) => {
    api
      .get(`/tenant/contrato/${entityId}/adjuntos/${id}/download`, getHeaders())
      .then((res: any) => {
        if (res.data.data && res.data.data.file_data) {
          const { file_data } = res.data.data;
          downloadFile(file_data, name);
          resolve("success");
        }

        resolve("danger");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const downloadAnnexes = (id: string, entityId: string, name?: string) =>
  new Promise((resolve, reject) => {
    api
      .get(
        `/tenant/contrato-anexo/${entityId}/adjuntos/${id}/download`,
        getHeaders()
      )
      .then((res: any) => {
        if (res.data.data && res.data.data.file_data) {
          const { file_data, file_name } = res.data.data;
          downloadFile(file_data, name || file_name);
          resolve("success");
        }

        resolve("danger");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const downloadImage = (
  id: string,
  entityId: string,
  documentType: string,
  name: string
) =>
  new Promise((resolve, reject) => {
    api
      .get(
        documentType === "adjunto"
          ? `/tenant/unidades/${entityId}/adjuntos/${id}/download`
          : `/tenant/unidades/${entityId}/imagenes/${id}/download`,
        getHeaders()
      )

      .then((res: any) => {
        if (res.data.data && res.data.data.file_data) {
          const { file_data } = res.data.data;
          downloadFile(file_data, name);
          resolve("success");
        }

        resolve("danger");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const downloadCheck = (id: string, name: string) =>
  new Promise((resolve, reject) => {
    api
      .get(`/tenant/check-unidad/${id}/pdf`, getHeaders())
      .then((res: any) => {
        if (res.data.data && res.data.data.file_data) {
          const { file_data } = res.data.data;
          downloadFile(file_data, name);
          resolve("success");
        }

        resolve("danger");
      })
      .catch((err) => {
        reject(err);
      });
  });
