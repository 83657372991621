
import Vue from "vue";
import { CommonSpace, NewReserve } from "@/types/commonSpace";
import {
  downloadAttachedFile,
  getCommonSpaces,
  reserveCommonSpace,
} from "@/actions/commonSpaces";
import Header from "@/components/Header.vue";
import DatePicker from "vue2-datepicker";
import { mapGetters } from "vuex";
import { Lease } from "@/types/lease";
import moment from "moment";
import {
  getAvailableDays,
  getDateWithTimeToApi,
  getFormattedValue,
} from "../utils";
import NotificationMessage from "@/components/NotificationMessage.vue";

export default Vue.extend({
  name: "ReserveCommonSpace",
  props: {
    color: String,
    fontSize: String,
    message: String,
    showIcon: Boolean,
  },
  components: { DatePicker, Header, NotificationMessage },
  methods: {
    style() {
      return `font-size: ${this.fontSize}`;
    },
    formattedValue(amount: string) {
      return getFormattedValue(amount);
    },
    getCommonSpace() {
      const { propertyId } = this.getSelectedLeaseInformation();
      const commonSpaceId = this.$route.query.commonSpaceId as string;
      if (propertyId) {
        this.propertyId = propertyId;
        getCommonSpaces(propertyId, commonSpaceId)
          .then(({ commonSpaces }) => {
            if (commonSpaces.length) {
              this.commonSpace = commonSpaces[0];
              this.commonSpace.images.forEach(({ id }, index) => {
                downloadAttachedFile(id, commonSpaceId).then((res) => {
                  this.commonSpace.images[index].src = res as string;
                });
              });
            }
          })
          .finally(() => (this.isLoading = false));
      }
    },
    getSelectedLeaseInformation() {
      const selectedLease = this.state.selectedLease;

      return this.state.leases.find((e: Lease) => e.id === selectedLease) || {};
    },
    getImageToShow(imageSrc: string) {
      return imageSrc || require("../assets/" + "images/default-image1.jpg");
    },
    isDisabledDate(date: Date) {
      const { endDate, isAutomaticRenovation } =
        this.getSelectedLeaseInformation();
      const availableDaysSet = new Set(this.commonSpace.availableDays);
      const formattedDate = moment(date).format("YYYY-MM-DD");
      const today = moment().format("YYYY-MM-DD");
      return (
        formattedDate < today ||
        (isAutomaticRenovation ? false : formattedDate > endDate) ||
        !availableDaysSet.has(date.getDay())
      );
    },
    getAvailableDaysToShow(availableDays: number[]) {
      return getAvailableDays(availableDays);
    },
    getSlotTimes() {
      const { maxTimeInMinutes } = this.commonSpace;
      const slotTimes = [];
      const availableFromTheTime = moment(
        this.commonSpace.availableFromTheTime,
        "HH:mm"
      );
      const availableToTheTime = moment(
        this.commonSpace.availableToTheTime,
        "HH:mm"
      );
      let startTime = moment(availableFromTheTime);

      while (
        startTime.isBefore(availableToTheTime) &&
        moment(startTime)
          .add(maxTimeInMinutes, "minutes")
          .isBefore(availableToTheTime)
      ) {
        const temporalSlot = {
          endTime: "",
          startTime: startTime.format("HH:mm"),
        };
        startTime.add(maxTimeInMinutes, "minutes");
        temporalSlot.endTime = startTime.format("HH:mm");
        slotTimes.push(temporalSlot);
      }

      const isTodayTheSelectedDate =
        moment(this.selectedDate).format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD");

      if (isTodayTheSelectedDate) {
        this.slotTimes = slotTimes.filter(
          (e) => e.startTime >= moment().format("HH:mm")
        );
      } else {
        this.slotTimes = slotTimes;
      }
    },
    goToConfirm() {
      this.isConfirming = true;
    },
    getFormattedDate() {
      return moment(this.selectedDate).format("dddd DD-MM-YYYY");
    },
    getUnits() {
      const units = [{ text: "Selecciona una unidad", value: null }];
      if (this.state.leases.length && this.state.selectedLease) {
        units.push(
          ...this.state.leases
            .find((e: Lease) => e.id === this.state.selectedLease)
            .units.map(
              (unit: { typeName: string; name: string; id: string }) => ({
                text: `${unit.typeName} ${unit.name}`,
                value: unit.id,
              })
            )
        );
      }
      return units;
    },

    reserve() {
      if (
        this.selectedDate &&
        this.selectedSlotIndex !== -1 &&
        this.selectedUnitId
      ) {
        const { startTime, endTime } = this.slotTimes[this.selectedSlotIndex];
        const [startHour, startMins] = startTime.split(":");
        const [endHour, endMins] = endTime.split(":");
        const startDate = new Date(this.selectedDate);
        const endDate = new Date(this.selectedDate);
        startDate.setHours(Number(startHour));
        startDate.setMinutes(Number(startMins));
        endDate.setHours(Number(endHour));
        endDate.setMinutes(Number(endMins));
        const commonSpaceId = this.$route.query.commonSpaceId as string;
        const residentId = this.state.leases.find(
          (e: Lease) => e.id === this.state.selectedLease
        ).residentId;

        const reserve: NewReserve = {
          fechaInicioReserva: getDateWithTimeToApi(startDate),
          fechaTerminoReserva: getDateWithTimeToApi(endDate),
          espacioComunReservaEstado: 100,
          cantidadParticipantes: 0,
          residente: residentId,
          espacioComun: commonSpaceId,
          unidad: this.selectedUnitId,
          detalle: "",
        };
        this.isReserving = true;
        reserveCommonSpace(reserve)
          .then(({ reserveId, error }) => {
            if (error === false) {
              this.$router.push({
                name: "my-reserve",
                query: { reserveId },
              });
            } else {
              this.errorMessageReservar = reserveId;
            }
          })
          .finally(() => {
            this.isReserving = false;
            this.isErrorReservar = true;
          });
      }
    },
  },
  watch: {
    "state.selectedLease"() {
      this.selectedUnitId = null;
      this.selectedDate = null;
      this.selectedSlotIndex = -1;
      this.isConfirming = false;
      const { propertyId } = this.getSelectedLeaseInformation();
      if (this.propertyId !== propertyId) {
        this.$router.push({ name: "community" });
      }
    },
    "state.leases"() {
      this.getCommonSpace();
    },
    selectedDate() {
      this.selectedSlotIndex = -1;
      this.getSlotTimes();
    },
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  created() {
    this.getCommonSpace();
  },
  data() {
    const commonSpace: CommonSpace = {} as CommonSpace;
    const slotTimes: { startTime: string; endTime: string }[] = [];

    return {
      commonSpace,
      interval: 999999999,
      isConfirming: false,
      isLoading: true,
      isReserving: false,
      propertyId: null,
      selectedDate: null,
      selectedSlotIndex: -1,
      selectedUnitId: null,
      isErrorReservar: false,
      errorMessageReservar: "",
      slotTimes,
    };
  },
});
