
import Vue from "vue";
import CommonSpaces from "@/components/CommonSpaces.vue";
import MainTabs from "@/components/MainTabs.vue";
import Header from "@/components/Header.vue";
import PublicationsList from "@/components/PublicationsList.vue";
import { mapGetters } from "vuex";
import { Lease } from "@/types/lease";
const allTabs = [
  { id: 0, title: "Espacios comunes" },
  { id: 1, title: "Publicaciones" },
];

export default Vue.extend({
  name: "CommunityView",
  components: { CommonSpaces, MainTabs, Header, PublicationsList },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onSelectTab(id: number) {
      this.selectedTab = id;
    },
    calculateTabs() {
      if (this.state.leases.length && this.state.selectedLease) {
        const showCommonSpace = this.state.leases.find(
          (e: Lease) => e.id === this.state.selectedLease
        ).permissions.showCommonSpace;
        if (!showCommonSpace) {
          this.tabs = [this.tabs[1]];
          this.selectedTab = 1;
        } else {
          this.tabs = allTabs;
          this.selectedTab = 0;
        }
        this.showTabs = true;
      }
    },
  },
  data() {
    return {
      tabs: [
        { id: 0, title: "Espacios comunes" },
        { id: 1, title: "Publicaciones" },
      ],
      selectedTab: 0,
      showTabs: false,
    };
  },
  created() {
    this.calculateTabs();
  },
  watch: {
    "state.leases"() {
      this.calculateTabs();
    },
    "state.selectedLease"() {
      this.calculateTabs();
    },
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
});
