
import { getResidents } from "@/actions/lease";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ResidentsView",
  created() {
    this.loadData();
  },
  data() {
    return {
      isLoading: true,
      residents: [],
      collapses: [],
    };
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  methods: {
    toggleCollapse(index: number) {
      this.$set(this.collapses, index, !this.collapses[index]);
    },
    loadData() {
      {
        this.isLoading = true;
        const { residents, selectedLease } = this.state;

        if (!residents[selectedLease]) {
          this.isLoading = true;
          getResidents(selectedLease).then(() => this.setResidents());
        } else {
          this.setResidents();
        }
      }
    },
    setResidents() {
      const { residents, selectedLease } = this.state;
      if (selectedLease && residents[selectedLease]) {
        this.isLoading = false;
        this.residents = residents[selectedLease];
        console.log(this.residents);
      }
    },
  },
  watch: {
    "state.selectedLease"() {
      this.loadData();
    },
    "state.leases"() {
      this.loadData();
    },
  },
});
