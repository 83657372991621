import api, { getHeaders } from "../utils/api";
import store from "@/store";

export const login = (password: string, username: string) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      username,
      password,
    };
    api
      .post(`/tenant/auth/login`, requestBody, getHeaders(false))
      .then((res) => {
        localStorage.setItem("pop_home_token", res.data.token);
        resolve("ok");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const confirmPassword = (
  email: string,
  password: string,
  token: string
) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      email,
      password: {
        first: password,
        second: password,
      },
      token,
    };
    api
      .post(`/tenant/auth/password-reset`, requestBody, getHeaders(false))
      .then((res) => {
        resolve(res.status === 200 ? "ok" : "");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const recoverPassword = (email: string) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      email,
    };
    api
      .post(`/tenant/auth/password-forgot`, requestBody, getHeaders(false))
      .then((res) => {
        resolve(res.data.errors ? "error" : "ok");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const logout = () => {
  localStorage.clear();
  store.commit("setInitialState");
};
