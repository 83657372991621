import api, { getHeaders } from "@/utils/api";
import store from "@/store";
import { User } from "@/types/user";

export const getUserInformation = () =>
  new Promise((resolve, reject) => {
    api
      .get(`/tenant/user`, getHeaders())
      .then((res) => {
        const {
          contacto: {
            apellido,
            email,
            fecha_nacimiento,
            genero,
            identificador,
            identificador_tipo,
            is_fumador,
            nacionalidad,
            nombre,
            ocupacion,
            telefono,
          },
          id,
        } = res.data.data.user_tenant[0];
        const payload: User = {
          birthday: fecha_nacimiento || "",
          dni: identificador,
          email,
          firstName: nombre,
          gender: genero && genero.nombre ? genero.nombre : "",
          id,
          identifierType: identificador_tipo?.nombre || "",
          isSmoker: is_fumador,
          lastName: apellido,
          nationality:
            nacionalidad && nacionalidad.nacionalidad
              ? nacionalidad.nacionalidad
              : "",
          occupation: ocupacion || "",
          phone: telefono || "",
        };
        store.commit("setUserInformation", payload);
        resolve("ok");
      })
      .catch((err) => {
        reject(err);
      });
  });
