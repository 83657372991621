
import Vue from "vue";
import { isAndroid, isChrome, isIOS, isSafari } from "mobile-device-detect";

export default Vue.extend({
  name: "PWAModal",
  methods: {},
  mounted() {
    if (window.matchMedia("(display-mode: standalone)").matches) {
      this.show = false;
    } else {
      this.show = true;
    }
  },
  data() {
    return {
      isIphoneInSafari: isSafari && isIOS,
      isAndroidInChrome: isAndroid && isChrome,
      show: false,
    };
  },
});
