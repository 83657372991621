
import Vue from "vue";
import { mapGetters } from "vuex";
import MainTabs from "@/components/MainTabs.vue";
import Header from "@/components/Header.vue";
import BillRecord from "@/components/BillRecord.vue";
import Parameters from "@/components/Parameters.vue";
import Annexes from "@/components/Annexes.vue";
import Documents from "@/components/DocumentsView.vue";
import Residents from "@/components/Residents.vue";

export default Vue.extend({
  name: "LeaseView",
  components: {
    MainTabs,
    Header,
    BillRecord,
    Parameters,
    Annexes,
    Documents,
    Residents,
  },
  methods: {
    onSelectTab(id: number) {
      this.selectedTab = id;
    },
    isLoading(): boolean {
      return this.state.loading.leases || this.state.loading.user;
    },
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  data() {
    return {
      tabs: [
        { id: 0, title: "Mis pagos" },
        { id: 1, title: "Contrato" },
        { id: 2, title: "Anexos" },
        { id: 3, title: "Residentes" },
        { id: 4, title: "Documentos" },
      ],
      selectedTab: 0,
    };
  },
});
