
import Vue from "vue";
import BillsPending from "@/components/BillsPending.vue";
import BillDetails from "@/components/BillDetails.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { getBills } from "@/actions/bill";
import { mapGetters } from "vuex";
import { Bill } from "@/types/bill";
import moment from "moment";
import { getFormattedValue } from "@/utils";

export default Vue.extend({
  name: "BillRecord",
  components: {
    BillsPending,
    BillDetails,
    NotificationMessage,
  },
  created() {
    this.loadData();
  },
  data() {
    const billToShowDetails: Bill = {} as Bill;
    const billRecord: Bill[] = [];

    return {
      billRecord,
      billToShowDetails,
      id: "",
      wasBillsLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  methods: {
    getBillRecord() {
      this.billRecord = this.state.bills[this.state.selectedLease].filter(
        (e: Bill) => e.stateId !== 100 && e.stateId !== 110
      );
    },
    formattedValue(amount: string) {
      return getFormattedValue(amount);
    },
    getStateColor(stateId: number) {
      return stateId === 100 ? "yellow" : stateId === 110 ? "red" : "green";
    },
    loadData() {
      {
        this.wasBillsLoaded = false;
        this.billToShowDetails = {} as Bill;
        this.id = this.state.selectedLease;

        if (!this.state.bills[this.id]) {
          getBills(this.id).then(() => {
            this.wasBillsLoaded = true;
            this.getBillRecord();
          });
        } else {
          this.wasBillsLoaded = true;
          this.getBillRecord();
        }
      }
    },
    getMonth(date: string): string {
      return moment(date).format("MMMM YYYY");
    },
    handleShowDetails(id: string) {
      if (id) {
        const bills: Bill[] = Object.values(this.state.bills[this.id]);
        const bill: Bill | undefined = bills.find((e: Bill) => e.id === id);
        if (bill) {
          this.billToShowDetails = bill;
        }
      } else {
        this.billToShowDetails = {} as Bill;
      }
    },
  },
  watch: {
    "state.selectedLease"() {
      this.loadData();
    },
    "state.leases"() {
      this.loadData();
    },
  },
});
