
import { getFormattedValue } from "@/utils";
import moment from "moment";
import Vue from "vue";
import Header from "@/components/Header.vue";
import { Bill } from "@/types/bill";
import { downloadBill, downloadBillToGenerate } from "@/actions/bill";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "BillsDetails",
  components: { Header },
  methods: {
    getMonth(): string {
      const { dateCreated, dateExpired } = this.bill;
      return moment(dateCreated || dateExpired).format("MMMM YYYY");
    },
    getDateToShow(): string {
      const { dateExpired } = this.bill;
      return moment(dateExpired).format("DD-MM-YYYY");
    },
    formattedValue(amount: string) {
      return getFormattedValue(amount);
    },
    getBillColor() {
      const { stateId } = this.bill;
      return stateId === 100 ? "yellow" : stateId === 110 ? "red" : "green";
    },
    getPagarButtonText() {
      const { stateId } = this.bill;
      return stateId === 300 || stateId === 310
        ? "Pagado"
        : stateId === -1
        ? "Por generar"
        : "Pagar";
    },
    downloadBill(id: string, stateId: number, dateCreated: string) {
      this.isDownloading = true;
      if (stateId === -1) {
        downloadBillToGenerate(dateCreated, this.state.selectedLease)
          .then((result) => (this.messageToShow = String(result)))
          .catch(() => (this.messageToShow = "danger"))
          .finally(() => {
            this.isDownloading = false;
            setTimeout(() => {
              this.messageToShow = "";
            }, 10000);
          });
      } else {
        downloadBill(id)
          .then((result) => (this.messageToShow = String(result)))
          .catch(() => (this.messageToShow = "danger"))
          .finally(() => {
            this.isDownloading = false;
            setTimeout(() => {
              this.messageToShow = "";
            }, 10000);
          });
      }
    },
    gotToPay(link: string) {
      window.open(link, "_blank");
    },
  },
  props: {
    bill: Object as () => Bill,
    goBack: Function,
  },
  data() {
    return {
      isDownloading: false,
      messageToShow: "",
    };
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
});
