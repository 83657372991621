
import { getAnnexes } from "@/actions/lease";
import { Annex } from "@/types/annex";
import Vue from "vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import { downloadAnnexes } from "@/actions/document";

export default Vue.extend({
  name: "AnnexesView",
  components: {
    NotificationMessage,
  },
  created() {
    this.loadData();
  },
  data() {
    const annexes: Annex[] = [];
    const downloadingFiles: string[] = [];

    return {
      isLoading: true,
      annexes,
      downloadingFiles,
      messageToShow: "",
      elementToShowMessage: "",
    };
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  methods: {
    loadData() {
      {
        const { annexes, selectedLease } = this.state;

        if (!annexes[selectedLease]) {
          this.isLoading = true;
          getAnnexes(selectedLease).then(() => this.setAnnexes());
        } else {
          this.setAnnexes();
        }
      }
    },
    setAnnexes() {
      const { annexes, selectedLease } = this.state;
      if (selectedLease && annexes[selectedLease]) {
        this.isLoading = false;
        this.annexes = annexes[selectedLease];
      }
    },
    getFormattedDate(date: string) {
      return moment(date).format("DD-MM-YYYY");
    },
    download(id: string, entityId: string) {
      this.downloadingFiles = this.downloadingFiles.concat([id]);
      downloadAnnexes(id, entityId)
        .then((result) => (this.messageToShow = String(result)))
        .catch(() => (this.messageToShow = "danger"))
        .finally(() => {
          this.elementToShowMessage = id;
          this.downloadingFiles = this.downloadingFiles.filter(
            (e: string) => e !== id
          );
          setTimeout(() => {
            this.messageToShow = "";
            this.elementToShowMessage = "";
          }, 10000);
        });
    },
  },
  watch: {
    "state.selectedLease"() {
      this.loadData();
    },
    "state.leases"() {
      this.loadData();
    },
  },
});
