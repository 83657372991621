
import Vue from "vue";
import { confirmPassword } from "@/actions/auth";

export default Vue.extend({
  name: "CompleteRegisterView",
  data() {
    return {
      form: {
        pass1: "",
        pass2: "",
      },
      loading: false,
      remenber: false,
      submitted: false,
    };
  },
  methods: {
    pass1Validation() {
      return this.submitted && this.getInvalidFeedbackPass1() ? false : null;
    },
    pass2Validation() {
      return this.submitted && this.getInvalidFeedbackPass2() ? false : null;
    },
    getInvalidFeedbackPass1() {
      const { pass1 } = this.form;

      if (!pass1) {
        return "Requerido";
      } else if (pass1.length < 8) {
        return "La contraseña debe tener al menos 8 caracteres";
      } else if (!/[A-Z]/.test(pass1)) {
        return "La contraseña debe tener al menos 1 letra mayúscula";
      } else if (!/[a-z]/.test(pass1)) {
        return "La contraseña debe tener al menos 1 letra minúscula";
      } else if (!/[0-9]/.test(pass1)) {
        return "La contraseña debe tener al menos 1 número";
      }

      return "";
    },
    getInvalidFeedbackPass2() {
      if (!this.form.pass2) {
        return "Requerido";
      } else if (this.form.pass1 !== this.form.pass2) {
        return "Las contraseñas deben ser iguales";
      }

      return "";
    },
    onSubmit(event: { preventDefault: () => void }) {
      const { token, email } = this.$route.params;
      event.preventDefault();
      this.submitted = true;
      const { pass1 } = this.form;

      if (this.pass1Validation() === null && this.pass2Validation() === null) {
        this.loading = true;
        confirmPassword(email, pass1, token)
          .then((res) => {
            if (res === "ok") {
              this.$router.push("/login");
            }
          })
          .finally(() => (this.loading = false));
      }
    },
  },
});
