
import Vue from "vue";
import Header from "@/components/Header.vue";
import { mapGetters } from "vuex";
import { ContactInformation, Lease } from "@/types/lease";
import { logout } from "@/actions/auth";

export default Vue.extend({
  name: "MyProfile",
  components: { Header },
  methods: {
    onClickAdminContact(type: string, value: string) {
      if (type === "correo") {
        window.open("mailto:" + value);
      } else if (type === "whatsapp") {
        window.open("https://wa.me/" + value);
      } else if (type === "telefono") {
        window.open("tel:" + value);
      }
    },
    getAdminContacts() {
      const adminContacts: {
        icon: string;
        value: string;
        onClick: () => void;
      }[] = [];
      if (this.state.selectedLease && this.state.leases) {
        const selectedLease = this.state.leases.find(
          (e: Lease) => e.id === this.state.selectedLease
        );

        selectedLease?.contactInformation?.forEach((e: ContactInformation) => {
          e.values.forEach((f) => {
            const isEmail = f.type === "correo";
            const isWhatsApp = f.type === "whatsapp";

            const icon = isEmail
              ? "fa-envelope"
              : isWhatsApp
              ? "fa-brands fa-whatsapp"
              : "fa-phone";

            const onClick = () =>
              window.open(
                isEmail
                  ? "mailto:"
                  : isWhatsApp
                  ? "https://wa.me/"
                  : "tel:" + f.value
              );
            adminContacts.push({
              icon,
              value: f.value,
              onClick,
            });
          });
        });
      }
      return adminContacts;
    },
    handleLogout() {
      logout();
      this.$router.push("/login");
    },
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  data() {
    return {
      isShowingMoreInfo: false,
    };
  },
});
