
import Vue from "vue";
import MenuVue from "@/components/Menu.vue";
import FooterIcons from "@/components/FooterIcons.vue";
import PWAModal from "@/components/PWAModal.vue";
import { getUserInformation } from "@/actions/user";
import { getLeases } from "@/actions/lease";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "HomeView",
  components: {
    FooterIcons,
    MenuVue,
    PWAModal,
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  created() {
    if (this.state.loading.user) {
      getUserInformation();
    }
    if (this.state.loading.leases) {
      getLeases();
    }
  },
});
