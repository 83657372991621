
import Vue from "vue";
import moment from "moment";
import { Publication } from "@/types/publication";
import { getPublications, downloadImage } from "@/actions/publication";
import { mapGetters } from "vuex";
import { Lease } from "@/types/lease";
import NotificationMessage from "@/components/NotificationMessage.vue";

export default Vue.extend({
  name: "PublicationsList",
  components: {
    NotificationMessage,
  },
  props: {
    seenFrom: {
      type: String,
      default: "",
    },
  },
  created() {
    this.getPublicationsToShow();
  },
  data() {
    const publications: Publication[] = [];

    return {
      publications,
      isLoading: true,
      perPage: 5,
      currentPage: 1,
      totalRows: 0,
    };
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  methods: {
    getPropertyId() {
      const selectedLease = this.state.selectedLease;

      return (
        this.state.leases.find((e: Lease) => e.id === selectedLease)
          ?.propertyId || ""
      );
    },
    getPublicationsToShow(page = 0) {
      const propertyId: string = this.getPropertyId();
      this.isLoading = true;
      this.publications = [];
      if (propertyId) {
        if (this.seenFrom === "home") {
          this.publications = this.state.publications[propertyId];
          this.isLoading = false;
        } else {
          getPublications(propertyId, true, page)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then(({ pager, publications }: any) => {
              this.publications = publications as Publication[];
              this.totalRows = pager.item_total;
              this.publications.forEach((e: Publication, i: number) => {
                if (e.imageId) {
                  downloadImage(e.imageId, propertyId, e.id, true).then(
                    (res) => {
                      this.publications[i].imageSrc = res as string;
                    }
                  );
                }
              });
            })
            .finally(() => (this.isLoading = false));
        }
      }
    },
    getDateToShow(date: string): string {
      return moment(date).format("DD [de] MMMM [de] YYYY");
    },
    getImageToShow(imageSrc: string) {
      return imageSrc || require("../assets/" + "images/default-image1.jpg");
    },
    handleChangePage(number: number) {
      this.currentPage = number;
      this.getPublicationsToShow(number);
    },
    getSectionDate(date: string): string {
      return moment(date).format("MMMM [de] YYYY");
    },
    canShowSectionDate(index: number) {
      return (
        index === 0 ||
        moment(this.publications[index - 1].publicationDate).format("MMMM") !==
          moment(this.publications[index].publicationDate).format("MMMM")
      );
    },
  },
  watch: {
    "state.selectedLease"() {
      this.getPublicationsToShow();
    },
    "state.leases"() {
      this.getPublicationsToShow();
    },
  },
});
