import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* import specific icons */
import {
  faChevronLeft,
  faArrowDown,
  faMagnifyingGlassDollar,
  faListCheck,
  faFileLines,
  faFileCirclePlus,
  faCircleCheck,
  faCircleXmark,
  faFileImage,
  faPowerOff,
  faBars,
  faHouse,
  faFileArrowDown,
  faCalendarCheck,
  faNewspaper,
  faCircleUser,
  faEnvelope,
  faPhone,
  faSliders,
  faDoorOpen,
  faMoneyCheckDollar,
  faPersonWalking,
  faDog,
  faCar,
  faTriangleExclamation,
  faPaperclip,
  faX,
  faAddressCard,
  faFlag,
  faCalendar,
  faVenusMars,
  faSmoking,
  faUserTie,
  faCircleDollarToSlot,
  faBookOpen,
  faInfoCircle,
  faPersonSwimming,
  faUsersLine,
  faCommentDots,
  faChevronDown,
  faChevronUp,
  faArrowRightFromBracket,
  faChevronRight,
  faBuilding,
  faPaw,
  faMoneyCheck,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/es";

/* add icons to the library */
library.add(
  faChevronLeft,
  faArrowDown,
  faMagnifyingGlassDollar,
  faListCheck,
  faFileLines,
  faFileCirclePlus,
  faCircleCheck,
  faCircleXmark,
  faFileImage,
  faPowerOff,
  faBars,
  faHouse,
  faFileArrowDown,
  faCalendarCheck,
  faNewspaper,
  faCircleUser,
  faEnvelope,
  faPhone,
  faWhatsapp,
  faSliders,
  faDoorOpen,
  faMoneyCheckDollar,
  faPersonWalking,
  faDog,
  faCar,
  faTriangleExclamation,
  faPaperclip,
  faX,
  faAddressCard,
  faFlag,
  faCalendar,
  faVenusMars,
  faSmoking,
  faUserTie,
  faCircleDollarToSlot,
  faBookOpen,
  faInfoCircle,
  faPersonSwimming,
  faUsersLine,
  faCommentDots,
  faChevronDown,
  faChevronUp,
  faArrowRightFromBracket,
  faChevronRight,
  faBuilding,
  faPaw,
  faCar,
  faMoneyCheck,
  faLocationDot
);

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);

import store from "./store";
import moment from "moment";

moment.locale("es");
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
