
import Vue from "vue";
import { PropType } from "vue/types/v3-component-props";

export default Vue.extend({
  name: "MainTabs",
  props: {
    onSelectTab: Function,
    selectedTab: Number as PropType<number>,
    tabs: Array as PropType<{ id: number; title: string }[]>,
  },
});
