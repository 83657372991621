
import { getReserves } from "@/actions/commonSpaces";
import { Reserve } from "@/types/commonSpace";
import Vue from "vue";
import Header from "@/components/Header.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import moment from "moment";
import { getFormattedValue } from "@/utils";

export default Vue.extend({
  name: "MyReserve",
  components: { Header, NotificationMessage },
  methods: {
    getReserve() {
      const reserveId = this.$route.query.reserveId as string;
      if (reserveId) {
        this.isLoading = true;
        getReserves({ id: reserveId })
          .then(({ reserves }) => {
            this.reserve = reserves[0];
            console.log(this.reserve);
          })
          .finally(() => (this.isLoading = false));
      }
    },
    getFormattedDate() {
      return `${moment(this.reserve.reservationDate).format(
        "dddd DD-MM-YYYY"
      )}. Desde las ${this.reserve.startTime} hrs. hasta las ${
        this.reserve.endTime
      } hrs.`;
    },
    formattedValue(amount: string) {
      return getFormattedValue(amount);
    },
  },
  data() {
    const reserve: Reserve = {} as Reserve;

    return {
      isLoading: true,
      reserve,
    };
  },
  created() {
    this.getReserve();
  },
});
