
import Vue from "vue";
import BillsPending from "@/components/BillsPending.vue";
import BillDetails from "@/components/BillDetails.vue";
import PublicationsList from "@/components/PublicationsList.vue";
import { getBills } from "@/actions/bill";
import { getPublications } from "@/actions/publication";
import { mapGetters } from "vuex";
import { Bill } from "@/types/bill";
import { Lease } from "@/types/lease";

export default Vue.extend({
  name: "BillView",
  components: {
    BillsPending,
    PublicationsList,
    BillDetails,
  },
  created() {
    this.loadData();
  },
  data() {
    const billToShowDetails: Bill = {} as Bill;

    return {
      billToShowDetails,
      id: "",
      showButtonToReserveCommonSpace: false,
      wasBillsLoaded: false,
      wasPublicationsLoaded: false,
    };
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  methods: {
    loadData() {
      {
        this.wasBillsLoaded = false;
        this.wasPublicationsLoaded = false;
        this.billToShowDetails = {} as Bill;
        this.id = this.state.selectedLease;
        const propertyId: string = this.getPropertyId(this.id);

        if (!this.state.bills[this.id]) {
          getBills(this.id).then(() => (this.wasBillsLoaded = true));
        } else {
          this.wasBillsLoaded = true;
        }

        if (propertyId) {
          if (!this.state.publications[propertyId]) {
            getPublications(propertyId).then(
              () => (this.wasPublicationsLoaded = true)
            );
          } else {
            this.wasPublicationsLoaded = true;
          }
        }

        if (this.state.leases.length && this.state.selectedLease) {
          this.showButtonToReserveCommonSpace = this.state.leases.find(
            (e: Lease) => e.id === this.state.selectedLease
          ).permissions.showCommonSpace;
        }
      }
    },
    isLoading(): boolean {
      return (
        this.state.loading.leases ||
        this.state.loading.user ||
        !this.wasBillsLoaded ||
        !this.wasPublicationsLoaded
      );
    },
    handleShowDetails(id: string) {
      if (id) {
        const bills: Bill[] = Object.values(this.state.bills[this.id]);
        const bill: Bill | undefined = bills.find((e: Bill) => e.id === id);
        if (bill) {
          this.billToShowDetails = bill;
        }
      } else {
        this.billToShowDetails = {} as Bill;
      }
    },
    getPropertyId(id: string) {
      return (
        this.state.leases.find((e: Lease) => e.id === id)?.propertyId || ""
      );
    },
  },
  watch: {
    "state.selectedLease"() {
      this.loadData();
    },
    "state.leases"() {
      this.loadData();
    },
  },
});
