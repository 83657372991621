/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import api, { getHeaders } from "@/utils/api";
import { Bill, DetailsByUnit, PaymentMethodLink } from "@/types/bill";
import { downloadFile, generateFilter } from "@/utils";
import store from "@/store";

const getBill = (bill: any): Bill => ({
  currencySymbol: bill.divisa_cobro.simbolo,
  dateCreated: bill.fecha_emision,
  datePaid: bill.fecha_pago,
  dateExpired: bill.fecha_vencimiento,
  folio: bill.folio,
  amount: bill.monto_pactado,
  detailsByUnit: getDetailsByUnit(bill.estado_cuenta_detalle),
  stateId: Number(bill.estado.id),
  stateName: bill.estado.nombre,
  paymentLink: getPaymentLink(bill.link_pago),
  id: bill.id,
  ...(bill.pagos && bill.pagos.length
    ? { paymentMethod: bill.pagos[0].medio_pago.nombre }
    : {}),
});

const getDetailsByUnit = (details: any[]): DetailsByUnit[] => {
  let detailsByUnit: DetailsByUnit[] = [];
  const unitsAndDetails: any = [];

  if (details) {
    details.forEach((f: any) => {
      if (unitsAndDetails[f.unidad.id]) {
        unitsAndDetails[f.unidad.id].push(f);
      } else {
        unitsAndDetails[f.unidad.id] = [f];
      }
    });
    for (const id in unitsAndDetails) {
      let unitName = "";
      let order = 1;
      const details: {
        amount: number;
        conceptName: string;
        conceptId: number;
      }[] = [];
      let total = 0;
      let rate = 0;
      let index = -1;

      unitsAndDetails[id].forEach((g: any) => {
        if (!unitName) {
          unitName = g.unidad.tipo + " " + g.unidad.nombre;
          order = g.unidad.tipo_orden;
        }
        if (Number(g.concepo.id) === 6) {
          rate += Number(g.monto_cobro);
        } else {
          total += Number(g.monto_cobro);
          if (g.concepo.id === 6) {
            g.nombre = "Interes";
          }
          index = details.findIndex(
            (d) => d.conceptName === g.nombre && [1, 2, 6].includes(d.conceptId)
          );
          if (index === -1) {
            details.push({
              amount: Number(g.monto_cobro),
              conceptName: g.nombre,
              conceptId: Number(g.concepo.id),
            });
          } else {
            details[index].amount += Number(g.monto_cobro);
          }
        }
      });

      detailsByUnit.push({
        unitName,
        details: details.sort((a, b) => a.conceptId - b.conceptId),
        total,
        rate,
        order,
      });
    }
    detailsByUnit = detailsByUnit.sort((a, b) => a.order - b.order);
  }
  return detailsByUnit;
};

const getPaymentLink = (links: any): PaymentMethodLink[] => {
  const metodosPago: PaymentMethodLink[] = [];
  if (links) {
    links.forEach((l: any) => {
      let nombre = "";
      if (l.indexOf("otrospagos") !== -1) {
        nombre = "OtrosPagos";
      }
      if (l.indexOf("servipag") !== -1) {
        nombre = "Servipag";
      }
      if (l.indexOf("payvalida") !== -1) {
        nombre = "Payvalida";
      }
      metodosPago.push({ url: l, nombre: nombre });
    });
  }
  return metodosPago;
};

const addBillToRecord = (
  bill: Bill,
  billsRecord: { [key: number]: Bill[] }
) => {
  const dateCreated: string = bill.dateCreated;
  const year = Number(dateCreated.split("-")[0]);
  if (billsRecord[year]) {
    billsRecord[year].push(bill);
  } else {
    billsRecord[year] = [bill];
  }
};

export const getBills = (leaseId: string) =>
  new Promise((resolve, reject) => {
    const filterToApply = generateFilter("estado_cuenta_filter", {
      contratoId: leaseId,
      estadoCuentaEstadoId: [100, 110, 300, 310],
    });
    api
      .get(`/tenant/estado-cuenta?${filterToApply}`, getHeaders())
      .then((res) => {
        const billsWithStatus300Or310: Bill[] = [];
        const billsWithStatus100: Bill[] = [];
        const billsWithStatus110: Bill[] = [];

        res.data.data.estado_cuenta.forEach((e: any) => {
          if (e.estado.id === "100") {
            billsWithStatus100.push(getBill(e));
          } else if (e.estado.id === "110") {
            billsWithStatus110.push(getBill(e));
          } else if (e.estado.id === "300" || e.estado.id === "310") {
            billsWithStatus300Or310.push(getBill(e));
          }
        });
        billsWithStatus300Or310.sort((a: Bill, b: Bill) =>
          moment(a.datePaid) > moment(b.datePaid) ? -1 : 1
        );
        const bills: Bill[] = [
          ...billsWithStatus110.sort((a: Bill, b: Bill) =>
            moment(a.dateExpired) > moment(b.dateExpired) ? 1 : -1
          ),
          ...billsWithStatus100.sort((a: Bill, b: Bill) =>
            moment(a.dateExpired) > moment(b.dateExpired) ? 1 : -1
          ),
        ].concat(
          billsWithStatus300Or310.length ? billsWithStatus300Or310[0] : []
        );

        store.commit("setBills", { bills, id: leaseId });
        resolve("ok");
      })
      .catch((err) => {
        store.commit("setBills", { bills: [], id: leaseId });
        reject(err);
      });
  });

export const getBillsRecord = (leaseId: string) =>
  new Promise((resolve, reject) => {
    const filterToApply = generateFilter("estado_cuenta_filter", {
      contratoId: leaseId,
    });
    api
      .get(`/tenant/estado-cuenta?${filterToApply}`, getHeaders())
      .then((res) => {
        const billsRecord: { [key: number]: Bill[] } = {};

        res.data.data.estado_cuenta.forEach((e: any) => {
          if (e.estado.id === "100") {
            addBillToRecord(getBill(e), billsRecord);
          } else if (e.estado.id === "110") {
            addBillToRecord(getBill(e), billsRecord);
          } else if (e.estado.id === "300" || e.estado.id === "310") {
            addBillToRecord(getBill(e), billsRecord);
          }
        });

        api
          .get(`/tenant/estado-cuenta/preview-list/${leaseId}`, getHeaders())
          .then((res) => {
            res.data.data.estado_cuenta_preview.forEach((e: any, i: number) => {
              const bill: Bill = getBill({
                ...e,
                estado: { id: -1 },
                id: String(`to-generate-${i}`),
              });

              addBillToRecord(bill, billsRecord);
            });

            for (const year in billsRecord) {
              billsRecord[year].sort((a: Bill, b: Bill) =>
                moment(a.dateCreated) < moment(b.dateCreated) ? -1 : 1
              );
            }

            store.commit("setBillsRecord", { billsRecord, id: leaseId });

            resolve("ok");
          })
          .catch((err) => {
            store.commit("setBillsRecord", { billsRecord: {}, id: leaseId });
            reject(err);
          });
      })
      .catch((err) => {
        store.commit("setBillsRecord", { billsRecord: {}, id: leaseId });
        reject(err);
      });
  });

export const downloadBill = (id: string) =>
  new Promise((resolve, reject) => {
    api
      .get(`/tenant/estado-cuenta/${id}/pdf`, getHeaders())
      .then((res: any) => {
        if (res.data.data && res.data.data.file_data) {
          const { file_data, file_name } = res.data.data;
          downloadFile(file_data, file_name);
          resolve("success");
        }
        resolve("danger");
      })
      .catch((err) => {
        reject(err);
      });
  });

export const downloadBillToGenerate = (dateCreated: string, leaseId: string) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      contratoId: leaseId,
      isPrimerPago: false,
      fecha: dateCreated,
    };

    api
      .post(`/tenant/estado-cuenta/preview `, requestBody, getHeaders())
      .then((res: any) => {
        if (res.data.data && res.data.data.file_data) {
          const { file_data, file_name } = res.data.data;
          downloadFile(file_data, file_name);
          resolve("success");
        }

        resolve("danger");
      })
      .catch((err) => {
        reject(err);
      });
  });
