
import Vue from "vue";
import { mapGetters } from "vuex";
import { Lease } from "@/types/lease";
import { selectLease } from "@/actions/lease";
import Header from "@/components/Header.vue";

export default Vue.extend({
  name: "MenuComponent",
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  components: { Header },
  methods: {
    getUnitToShow() {
      const selectedLease = this.state.leases.find(
        (e: Lease) => e.id === this.state.selectedLease
      );
      if (selectedLease) {
        const { name, typeName } = selectedLease.units[0];
        return `${typeName} ${name}`;
      }
      return "";
    },
    getOtherUnitsToShow(lease: Lease) {
      let otherUnitsToShow = "";
      for (let index = 0; index < lease.units.length; index++) {
        const { name, typeName } = lease.units[index];
        const unitInfo = `${typeName} ${name}`;
        if (index === lease.units.length - 1) {
          otherUnitsToShow += unitInfo;
        } else {
          otherUnitsToShow += `${unitInfo}, `;
        }
      }
      return otherUnitsToShow;
    },
    isSelectedLease(lease: Lease) {
      return lease.id === this.state.selectedLease ? "selected-lease" : "";
    },
    onSelectLease(leaseId: string, hide: () => void) {
      selectLease(leaseId);
      hide();
    },
  },
});
