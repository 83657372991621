
import Vue from "vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import {
  downloadLease,
  getAnnexes,
  getCheckin,
  getCheckout,
  getLeases,
} from "@/actions/document";
import { Document } from "@/types/document";

export default Vue.extend({
  name: "DocumentsView",
  components: {
    NotificationMessage,
  },
  created() {
    this.loadData();
  },
  data() {
    const documents: { id: string; name: string; entityId: string }[] = [];
    const downloadingFiles: string[] = [];

    return {
      isLoadingLeases: true,
      isLoadingCheckIn: true,
      isLoadingCheckOut: true,
      isLoadingAnnexes: true,
      documents,
      downloadingFiles,
      messageToShow: "",
      elementToShowMessage: "",
    };
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  methods: {
    isLoading() {
      return (
        this.isLoadingLeases ||
        this.isLoadingCheckIn ||
        this.isLoadingCheckOut ||
        this.isLoadingAnnexes
      );
    },
    loadData() {
      {
        const { documents, selectedLease } = this.state;
        this.documents = [];
        if (!documents[selectedLease].leases) {
          this.isLoadingLeases = true;
          getLeases(selectedLease).then(() => this.setLeases());
        } else {
          this.setLeases();
        }

        if (!documents[selectedLease].checkIn) {
          this.isLoadingCheckIn = true;
          getCheckin(selectedLease).then(() => this.setCheckIn());
        } else {
          this.setCheckIn();
        }

        if (!documents[selectedLease].checkOut) {
          this.isLoadingCheckOut = true;
          getCheckout(selectedLease).then(() => this.setCheckOut());
        } else {
          this.setCheckOut();
        }

        if (!documents[selectedLease].annexes) {
          this.isLoadingAnnexes = true;
          getAnnexes(selectedLease).then(() => this.setAnnexes());
        } else {
          this.setAnnexes();
        }
      }
    },
    setLeases() {
      const { documents, selectedLease } = this.state;
      if (selectedLease && documents[selectedLease].leases) {
        this.isLoadingLeases = false;
        this.documents = this.documents.concat(
          documents[selectedLease].leases.map((e: Document) => ({
            id: e.id,
            entityId: e.entityId,
            name: e.name,
          }))
        );
      }
    },
    setCheckIn() {
      const { documents, selectedLease } = this.state;
      if (selectedLease && documents[selectedLease].checkIn) {
        this.isLoadingCheckIn = false;
        this.documents = this.documents.concat(
          documents[selectedLease].checkIn.map((e: Document) => ({
            id: e.id,
            entityId: e.entityId,
            name: e.name,
          }))
        );
      }
    },
    setCheckOut() {
      const { documents, selectedLease } = this.state;
      if (selectedLease && documents[selectedLease].checkOut) {
        this.isLoadingCheckOut = false;
        this.documents = this.documents.concat(
          documents[selectedLease].checkOut.map((e: Document) => ({
            id: e.id,
            entityId: e.entityId,
            name: e.name,
          }))
        );
      }
    },
    setAnnexes() {
      const { documents, selectedLease } = this.state;
      if (selectedLease && documents[selectedLease].annexes) {
        this.isLoadingAnnexes = false;
        this.documents = this.documents.concat(
          documents[selectedLease].annexes.map((e: Document) => ({
            id: e.id,
            entityId: e.entityId,
            name: e.name,
          }))
        );
      }
    },
    getFormattedDate(date: string) {
      return moment(date).format("DD-MM-YYYY");
    },
    download(id: string, entityId: string, name: string, type: "lease") {
      this.downloadingFiles = this.downloadingFiles.concat([id]);
      if (type === "lease") {
        downloadLease(id, entityId, name)
          .then((result) => (this.messageToShow = String(result)))
          .catch(() => (this.messageToShow = "danger"))
          .finally(() => {
            this.elementToShowMessage = id;
            this.downloadingFiles = this.downloadingFiles.filter(
              (e: string) => e !== id
            );
            setTimeout(() => {
              this.messageToShow = "";
              this.elementToShowMessage = "";
            }, 10000);
          });
      }
    },
  },
  watch: {
    "state.selectedLease"() {
      this.loadData();
    },
    "state.leases"() {
      this.loadData();
    },
  },
});
