
import {
  getDocuments,
  getParameters,
  getPets,
  getUnits,
  getVehicles,
} from "@/actions/lease";
import { Parameters } from "@/types/parameter";
import { Pet } from "@/types/pet";
import { Unit } from "@/types/unit";
import { getFormattedValue } from "@/utils";
import moment from "moment";
import Vue from "vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { mapGetters } from "vuex";
import { Vehicle } from "@/types/vehicle";
import { CustodyDocument } from "@/types/custodyDocument";

export default Vue.extend({
  name: "ParametersView",
  components: {
    NotificationMessage,
  },
  created() {
    this.loadData();
  },
  data() {
    const parameters: Parameters = {} as Parameters;
    const units: Unit[] = [];
    const pets: Pet[] = [];
    const vehicles: Vehicle[] = [];
    const custodyDocuments: CustodyDocument[] = [];

    return {
      isLoadingParameters: true,
      isLoadingUnits: true,
      isLoadingPets: true,
      isLoadingVehicles: true,
      isLoadingCustodyDocuments: true,
      parameters,
      units,
      pets,
      vehicles,
      custodyDocuments,
    };
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  mounted() {
    this.setParameters();
  },
  methods: {
    isLoading() {
      return (
        this.isLoadingParameters ||
        this.isLoadingUnits ||
        this.isLoadingPets ||
        this.isLoadingVehicles ||
        this.isLoadingCustodyDocuments
      );
    },
    getDateToShow(date: string): string {
      return moment(date).format("DD-MM-YYYY");
    },
    getDuration() {
      const { startDate, endDate } =
        this.state.parameters[this.state.selectedLease];
      return (
        moment
          .duration(moment(endDate).diff(moment(startDate)))
          .asMonths()
          .toFixed(0) + " meses"
      );
    },
    loadData() {
      {
        const {
          parameters,
          pets,
          selectedLease,
          units,
          vehicles,
          custodyDocuments,
        } = this.state;

        if (!parameters[selectedLease]) {
          this.isLoadingParameters = true;
          getParameters(selectedLease).then(() => this.setParameters());
        } else {
          this.setParameters();
        }

        if (!units[selectedLease]) {
          this.isLoadingUnits = true;
          getUnits(selectedLease).then(() => this.setUnits());
        } else {
          this.setUnits();
        }

        if (!pets[selectedLease]) {
          this.isLoadingPets = true;
          getPets(selectedLease).then(() => this.setPets());
        } else {
          this.setPets();
        }

        if (!vehicles[selectedLease]) {
          this.isLoadingVehicles = true;
          getVehicles(selectedLease).then(() => this.setVehicles());
        } else {
          this.setVehicles();
        }

        if (!custodyDocuments[selectedLease]) {
          this.isLoadingCustodyDocuments = true;
          getDocuments(selectedLease).then(() => this.setCustodyDocuments());
        } else {
          this.setCustodyDocuments();
        }
      }
    },
    setParameters() {
      const { parameters, selectedLease } = this.state;
      if (selectedLease && parameters[selectedLease]) {
        this.isLoadingParameters = false;
        this.parameters = parameters[selectedLease];
      }
    },
    setUnits() {
      const { units, selectedLease } = this.state;
      if (selectedLease && units[selectedLease]) {
        this.isLoadingUnits = false;
        this.units = units[selectedLease];
      }
    },
    setPets() {
      const { pets, selectedLease } = this.state;
      if (selectedLease && pets[selectedLease]) {
        this.isLoadingPets = false;
        this.pets = pets[selectedLease];
      }
    },
    setVehicles() {
      const { vehicles, selectedLease } = this.state;
      if (selectedLease && vehicles[selectedLease]) {
        this.isLoadingVehicles = false;
        this.vehicles = vehicles[selectedLease];
      }
    },
    setCustodyDocuments() {
      const { custodyDocuments, selectedLease } = this.state;
      if (selectedLease && custodyDocuments[selectedLease]) {
        this.isLoadingCustodyDocuments = false;
        this.custodyDocuments = custodyDocuments[selectedLease];
      }
    },
    formattedValue(amount: string) {
      return getFormattedValue(amount);
    },
  },
  watch: {
    "state.selectedLease"() {
      this.loadData();
    },
    "state.leases"() {
      this.loadData();
    },
  },
});
