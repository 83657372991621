var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"common-spaces-container"},[_c('div',{staticClass:"tabs-container"},_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:index,staticClass:"tab-container",class:index === _vm.selectedTab ? 'selected-tab' : '',on:{"click":function($event){return _vm.onSelectTab(index)}}},[_vm._v(" "+_vm._s(tab)+" ")])}),0),_c('b-overlay',{class:(_vm.selectedTab === 0 && _vm.isLoadingCommonSpaces) ||
      (_vm.selectedTab === 1 && _vm.isLoadingMyReserves)
        ? 'overlay-common-spaces'
        : '',attrs:{"show":(_vm.selectedTab === 0 && _vm.isLoadingCommonSpaces) ||
      (_vm.selectedTab === 1 && _vm.isLoadingMyReserves),"rounded":"sm"}}),(!_vm.isLoadingCommonSpaces && _vm.selectedTab === 0)?_c('div',{staticClass:"common-spaces-list"},[_c('div',{key:"input-for-search",staticClass:"input-filter"},[_c('b-form-input',{attrs:{"placeholder":"Filtrar"},on:{"input":_vm.handleInput},model:{value:(_vm.searchTextInCommonSpaces),callback:function ($$v) {_vm.searchTextInCommonSpaces=$$v},expression:"searchTextInCommonSpaces"}})],1),(_vm.commonSpacesFiltered.length === 0)?_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" Sin resultados! ")]):_vm._e(),_vm._l((_vm.commonSpacesFiltered),function(commonSpace){return _c('div',{key:commonSpace.id,staticClass:"common-space-container",on:{"click":function($event){return _vm.$router.push({
          name: 'reserve-common-space',
          query: { commonSpaceId: commonSpace.id },
        })}}},[_c('img',{attrs:{"src":_vm.getImageToShow(_vm.commonSpaceToImage[commonSpace.id])}}),_c('div',{staticClass:"description"},[_c('h4',[_vm._v(_vm._s(commonSpace.name))]),_c('div',[_vm._v(" "+_vm._s(_vm.formattedValue(commonSpace.reserveValue))+" "+_vm._s(commonSpace.currencyCode)+" ")]),_c('div',[_vm._v(_vm._s(_vm.getAvailableDaysToShow(commonSpace.availableDays)))])])])})],2):_vm._e(),(!_vm.isLoadingMyReserves && _vm.selectedTab === 1)?_c('div',{staticClass:"my-reserves-list"},[(_vm.myFutureReserves.length)?_c('div',{staticClass:"reserves-container"},[_c('h6',[_vm._v("Futuras")]),_c('b-row',{staticClass:"strong-text"},[_c('b-col',[_vm._v("Espacio común")]),_c('b-col',[_vm._v("Fecha")])],1),_vm._l((_vm.myFutureReserves),function(reserve){return _c('fragment',{key:reserve.id},[_c('b-row',{staticClass:"regular-text",on:{"click":function($event){return _vm.$router.push({
              name: 'my-reserve',
              query: { reserveId: reserve.id },
            })}}},[_c('b-col',[_vm._v(_vm._s(reserve.commonSpaceName))]),_c('b-col',[_vm._v(_vm._s(_vm.getDateToShow(reserve.reservationDate)))])],1)],1)})],2):_vm._e(),(_vm.myPastReserves.length)?_c('div',{staticClass:"reserves-container"},[_c('h6',[_vm._v("Pasadas")]),_c('b-row',{staticClass:"strong-text"},[_c('b-col',[_vm._v("Espacio común")]),_c('b-col',[_vm._v("Fecha")])],1),_vm._l((_vm.myPastReserves),function(reserve){return _c('fragment',{key:reserve.id},[_c('b-row',{staticClass:"regular-text",on:{"click":function($event){return _vm.$router.push({
              name: 'my-reserve',
              query: { reserveId: reserve.id },
            })}}},[_c('b-col',[_vm._v(_vm._s(reserve.commonSpaceName))]),_c('b-col',[_vm._v(_vm._s(_vm.getDateToShow(reserve.reservationDate)))])],1)],1)})],2):_vm._e(),(_vm.myPastReserves.length === 0 && _vm.myFutureReserves.length === 0)?_c('div',[_c('NotificationMessage',{attrs:{"message":"No existen reservas para este contrato","color":"yellow","fontSize":"13px"}})],1):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }