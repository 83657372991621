
import Vue from "vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { selectLease, downloadImage } from "@/actions/lease";
import { mapGetters } from "vuex";
import { Lease } from "@/types/lease";

export default Vue.extend({
  name: "SelectLease",
  components: { NotificationMessage },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  methods: {
    isLoading() {
      return this.state.loading.leases || this.state.loading.user;
    },
    clickInLease(id: string) {
      selectLease(id);
      this.$router.push({ name: "bill" });
    },
    getImageToShow(imageSrc: string) {
      return imageSrc || require("../assets/" + "images/default-image.jpg");
    },
  },
  data() {
    return {
      interval: 999999999,
    };
  },
  watch: {
    "state.leases"(newValue) {
      newValue.forEach((e: Lease) => {
        if (!e.imageSrc) {
          downloadImage(e.imageId, e.id, e.propertyId);
        }
      });
    },
  },
});
